<template>
  <v-card>
    <v-toolbar
      dark
      color="primary"
    >
      <v-btn
        icon
        dark
        @click="close"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>Confirmação de leitura - {{ $attrs.title }}</v-toolbar-title>
      <v-spacer />
      <v-toolbar-items>
        <v-btn
          dark
          text
          @click="handleSaveFile()"
        >
          Exportar para Excel
          <v-icon>mdi-file-excel</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-data-table
      :headers="headerUser"
      :items="$attrs.data"
    >
      <template #item.createdAt="{ item }">
        <p>{{ item.createdAt | parseDate }}</p>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>

import * as XLSX from 'xlsx';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';

export default {
  name: 'ListUserSeen',
  props: {
    populateWith: {
      type: Object,
      title: String,
      data: [],
      default: () => {},
    },
  },
  data() {
    return {
      headerUser: [
        {
          text: 'Usúario',
          align: 'start',
          sortable: false,
          value: 'userAd',
        },
        {
          text: 'Visualizado',
          sortable: false,
          value: 'createdAt',
        },
      ],
    };
  },
  methods: {
    close() {
      this.$emit('close-dialog');
    },

    async handleSaveFile() {
      const sheetData = await this.filterObjectFile();
      const sheetTitle = this.filterTitle(this.$attrs.title);
      const workSheet = XLSX.utils.json_to_sheet(sheetData);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, sheetTitle);
      const excelBuffer = XLSX.write(workBook, { bookType: 'xls', type: 'array' });
      this.saveAsExcel(excelBuffer, sheetTitle);
    },

    filterTitle(title) {
      const sliceTitle = title.slice(0, 20);
      return sliceTitle.replace(/\s+/g, '_');
    },

    saveAsExcel(buffer, filename) {
      const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const EXCEL_EXTENSION = '.xls';
      const data = new Blob([buffer], { type: EXCEL_TYPE });
      saveAs(data, `${filename}${EXCEL_EXTENSION}`);
    },

    filterObjectFile() {
      return this.$attrs.data.map(
        ({
          createdAt,
          userAd,
        }) => {
          const noticeUserSeenFiltered = {
            Usuario: userAd,
            Visualizado: dayjs(createdAt).format('DD/MM/YYYY HH:mm:ss'),
          };
          return noticeUserSeenFiltered;
        },
      );
    },

  },
};
</script>
